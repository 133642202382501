/* FeeprivacyCalculator.css */

/* Styles for the FeeprivacyCalculator component */

/* Main container */
.privacycalculator-container {
  padding: 40px;
  padding-left: 25vw;
  padding-right: 20vw;
  font-family: "Poppins", sans-serif;
}

/* Form elements */
/* .privacycalculator-container form {
  margin-bottom: 20px;
} */

.privacycalculator-container label {
  display: block;
  margin-bottom: 5px;
  color: black;
}

.privacycalculator-container input {
  width: 60%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #5c00cd;
  background-color: white;
  color: black;
  margin-bottom: 10px;
}

.privacycalculator-container button {
  background-color: #5c00cd;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.btn-container {
  margin-top: 20px;
  width: 200px;
  /* display: flex; */
  /* justify-content: space-around; */
}

.privacycalculator-container > div {
  /* visibility: hidden; */
  border: 1px solid gray;
  border-radius: 10px;

  background-color: rgb(185, 185, 185);
  margin: 40px 0;
  user-select: none;
}

.privacycalculator-container > div > div {
  padding: 0 15px;
}
