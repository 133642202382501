@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primary-color: #002239;

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  // width: 775px;
  // height: 500px;
  // background-color: #f6f6f6;
  background-color: #f0f0f0;
  // background-color: white;
  ul {
    list-style: none;
    padding: 0;
  }

  .toggle-button-container {
    display: flex;
    button {
      padding: 0.75em 1em;
      border: none;
      border-radius: 6px;
      background-color: white;
      border: 1px solid #acacac;
      cursor: pointer;
    }
    button + button {
      margin-left: 1em;
    }
    button.selected {
      background-color: #1872f6;
      border: none;
      color: white;
    }
  }

  .body {
    button {
      padding: 0.5em 1em;
      // padding: 0;
      border: none;
      border-radius: 6px;
      border: 1px solid #acacac;
      cursor: pointer;
    }
    button + button {
      margin-left: 1em;
    }
    button.selected {
      background-color: #1872f6;
      border: none;
      color: white;
    }
    padding: 8px;
    main {
      position: relative;
      top: 0px;
      left: calc(15vw + 1.5vw + 3vw + 16px);
      width: calc(85vw - 3vw - 3vw - 32px);
      border-radius: 6px;
      & > div {
        width: 100%;
        .content {
          // padding: 0 1rem 0 1rem;
          margin-bottom: 4em;
          min-height: 400px;
          border-radius: 0 0 6px 6px;
          width: 100%;
          // border-inline: 0.5px solid rgb(222, 222, 222);
          // border-bottom: 0.5px solid rgb(222, 222, 222);
          // background-color: white;
          // background-color: $off-gray-bg;
          background-color: transparent;
          .primary-content {
            display: flex;
            h4 {
              font-weight: 400;
              width: 25%;
              height: fit-content;
              padding: 1em 2em 1em 2em;
              border-radius: 6px;
              border: 0.5px solid rgb(222, 222, 222);
              background-color: white;
              box-shadow: 0px 2px 12px 0px rgba(153, 173, 207, 0.4);

              p {
                font-size: 1.25rem;
                font-weight: 500;
                margin: 0;
                padding: 0.25em 0 0 0;
                height: 1.5em;
              }
            }
            h4 + h4 {
              margin-left: 2em;
            }
          }
          .secondary-content {
            .tables {
              display: flex;
              .table + .table {
                margin-left: 2rem;
              }
              .table {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 45%;
                padding: 1em 1.5em;
                border-radius: 6px;
                border: 0.5px solid rgb(222, 222, 222);
                background-color: white;
                box-shadow: 0px 2px 12px 0px rgba(153, 173, 207, 0.4);
                h5 {
                  font-size: 1.1rem;
                  font-weight: 500;
                  // color: rgb(38, 38, 135);
                  margin: 0;
                  // padding: 0 1.25em;
                  padding: 0;
                }
                ul {
                  margin: 1em 0 0 0;
                  li {
                    font-size: 0.9rem;
                    // font-weight: 300;
                    display: flex;
                    justify-content: space-between;
                    padding: 0.75em 0;
                    // padding: 0 1.25em;
                    border-top: 1px solid rgb(194, 202, 212);
                    p {
                      margin: 0;
                    }
                  }
                }
              }
            }
            h4 {
              font-size: 1.1rem;
              font-weight: 500;
              margin-top: 2em;
            }
          }
        }
      }
    }
  }

  nav {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 19.5vw;
    height: calc(100vh - 16px);
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1.5vw 0 1.5vw;
    border-radius: 6px;
    background-color: #002239;
    z-index: 10;
    svg {
      width: 2em;
      height: 2em;
      margin-right: 0.75em;
    }
    a:has(img) {
      width: 10vw;
      align-self: center;
      margin: 2em 0 2em 0;
    }
    .options {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(100% - 0vw);

      ul {
        // display: grid;
        // justify-content: flex-start;
        flex-direction: column;
        margin-block: 0.5em;
        // margin-left: auto;
        font-size: 0.8rem;
        // height: 100%;
        width: 100%;
        li + li {
          // margin-left: 1.5em;
        }
        li {
          width: 100%;
          cursor: pointer;
          padding: 0.75em 1.5em;
          margin: 0 0 0.5em 0;
          border-radius: 6px;
          display: flex;
          align-items: center;
          text-transform: capitalize;
          a {
            color: white;
            text-decoration: none;
            display: flex;
            align-items: center;
            white-space: nowrap;
          }
          &.selected {
            background-color: #00435c;
          }
          &:hover {
            background-color: #013141;
          }
        }
      }
      .bottom {
        padding: 0 1.5em 2em;
        font-size: 0.8rem;
        div.credits-wrapper {
          div:has(.progress-bar) {
            display: flex;
            justify-content: left;
            align-items: center;
            .progress-bar {
              width: 100%;
              height: 8px;
              background-color: #c8c8c8;
              margin-left: 0.5em;
              border-radius: 4px;
              overflow: hidden;
              div {
                height: 100%;
                background-color: #034094;
                border-radius: 4px;
              }
            }
          }
        }
        p:not(.sign-out-button):not(.email) {
          color: white;
          margin: 0;
          font-weight: 300;
          font-size: 0.9rem;
        }
        div.email {
          color: white;
          font-size: 0.9rem;
          cursor: pointer;
          margin-bottom: 0;
          div {
            display: grid;
            place-items: center;
            // padding: 0.5em;
            font-size: 1rem;
            width: 2.5em;
            height: 2.5em;
            margin-right: 0.5em;
            border-radius: 50%;
            background-color: #005675;
          }
        }
        .more {
          // padding: 0.75em 1.5em;
          position: relative;
          color: white;
          & > div:nth-child(1) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            top: 0;
            transform: translateY(-110%);
            padding: 1.75em;
            border-radius: 6px;

            background-color: #005675;

            a {
              color: white;
              text-decoration: none;
            }
            .sign-out-button {
              color: #ffa9a9;
              cursor: pointer;
              width: fit-content;
              margin-bottom: 0;
            }
          }
          & > div:nth-child(2) {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-top: 2em;
            svg {
              width: 1.5em;
              height: 1.5em;
            }
          }
        }
      }
    }
  }
  .top {
    background-color: $primary-color;
    padding: 1em 0;
    border-radius: 6px;
  }
  .page {
    position: relative;
    // width: max(36vw, 360px);
    // width: 80vw;
    // height: 200vh;
    // width: 100vw;
    // height: 100vh;
    // width: 775px;
    // height: 500px;
    // overflow: hidden;
    background-color: transparent;

    .container {
      // position: fixed;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      // height: 100%;
      // width: max(27.5%, 275px);
      // width: 80vw;
      // width: 775px;
      // height: 500px;
      // width: max(36vw, 360px);
      // height: 199vh;
      // padding: 1.5vw;
      // background-color: #f4fbff;
      width: 100vw;
      min-height: 100vh;
      box-shadow: 0px 0px 107px 15px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      font-family: "Poppins", sans-serif;
      // overflow-y: overlay;
      & > main {
        // margin-bottom: 10vh;
      }

      button {
        font-family: "Poppins", sans-serif;
      }
      // img {
      //   width: 30%;
      // }
      .search-container {
        position: relative;
        width: 50%;
        margin-inline: auto;
        form {
          padding: 0.5em 1em;
          display: flex;
          align-items: center;
          border: 1px solid #e4e0e0;
          border-radius: 8px;
          background-color: white;
          // margin-top: 1em;
          input {
            border: none;
            width: 100%;
            font-size: 1.05rem;
            // font-weight: 600;
            &:focus {
              outline: none;
            }
          }
          svg {
            margin-inline: 0.5em;
            cursor: pointer;
          }
          button {
            padding: 0.5em 1em;
            border: none;
            border-radius: 6px;
            color: white;
            background-color: #5c00cd;
          }
        }
        #search-matches {
          position: absolute;
          display: none;
          width: max(25vw, 250px);
          padding: 0.75em 1em;
          font-size: 0.75rem;
          border: 1px solid #e4e0e0;
          border-radius: 12px;
          background-color: white;
          margin-top: 0.5em;
          div {
            display: flex;
            align-items: center;
            p {
              margin: 0;
              width: 100%;
            }
            & > button {
              font-size: 0.75rem;
              margin-inline: 0.5em;
            }
          }
          table {
            font-size: 0.75rem;
            background-color: white;
          }
        }
      }

      &::-webkit-scrollbar {
        // width: 15px;
        width: 8px;
        height: 0;
        background: white;
      }
    }
    #dataContainer {
      display: flex;
      margin-top: 1em;
      overflow-x: auto;
      padding-bottom: 1em;
      a {
        text-decoration: none;
        color: #170231;
        & > div {
          border-radius: 6px;
          padding: 0.4em 0.5em 1em 0.5em;
          background-color: white;
          box-shadow: 0px 2px 8px 0px rgba(153, 173, 207, 0.4);
          div:nth-child(1) {
            width: max(12vw, 120px);
            height: max(12vw, 120px);
            position: relative;
            overflow: hidden;
            background-color: #f6f6f6;
            img {
              border-radius: 6px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              max-width: 100%;
              max-height: 100%;
              // width: 100%;
            }
          }
          p {
            font-size: 0.75rem;
            text-align: left;
            width: max(12vw, 120px);
          }
          div:has(span) {
            display: flex;
            span {
              font-size: 0.75rem;
              text-align: left;
              display: flex;
              align-items: center;
              margin-right: 0.75em;
              svg {
                margin-left: 0.25em;
              }
            }
          }
        }
      }
      a + a {
        margin-left: 1em;
      }
      &::-webkit-scrollbar {
        // width: 15px;
        // width: 8px;
        height: 8px;
        background: white;
      }
    }
    footer {
      // width: 100%;
      left: calc(15vw + 1.5vw + 3vw + 24px);
      width: calc(85vw - 3vw - 3vw - 32px);
      margin-top: 2em;
      position: absolute;
      bottom: 6px;
      // transform: translateY(100%);
      border-radius: 6px;
      // background-color: black;

      // .divider {
      //   height: 2em;
      //   background-color: #f6f6f6;
      // }
      p {
        padding: 1em 0 1em 1.5vw;
        color: rgb(87, 87, 87);
        font-size: 0.9rem;
        margin: 0;
        text-align: center;
      }
    }
  }
}

button {
  cursor: pointer;
}

// #DFF4FF
section:first-child {
  // border: 1px solid red;
}

*::-webkit-scrollbar-track {
  border-radius: 100vw;
  // margin-top: 18px;
  background-color: #f6f6f6;
}

*::-webkit-scrollbar-thumb {
  // border: 1px solid #9dcfdd;
  border-radius: 100vw;
  background: #b8d5dd;
}

*::-webkit-scrollbar-thumb:hover {
  background: lightblue;
}

// @supports (scrollbar-color: red blue) {
//   * {
//     scrollbar-color: red blue;
//   }
// }

@media (max-width: 768px) {
  ::-webkit-scrollbar-track {
    margin-top: 0;
  }
}
