/* AddCredits component CSS */
.add-credits-popup {
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .add-credits-popup h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .add-credits-popup label {
    display: block;
    margin-bottom: 10px;
  }
  
  .add-credits-popup input[type="number"] {
    width: 100%;
    padding: 8px;
    border-radius: 3px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .add-credits-popup button {
    padding: 8px 16px;
    background-color: #5c00cd;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .add-credits-popup button:hover {
    background-color: #7a36cc;
  }
  
  .add-credits-popup p {
    margin-top: 10px;
    font-size: 0.9rem;
  }
  