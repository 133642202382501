.product-finder {
  .secondary-search-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
    margin-top: 1em;
    padding: 1.5em;
    border-radius: 6px;
    background-color: white;
    label {
      width: 7em;
    }
    & > div {
      // width: 100%;

      & > div {
        display: flex;
        align-items: center;
        margin-right: 1em;
        input {
          width: 9em;
          padding: 0.75em 1em;
          border: none;
          border: 1px solid #9e9e9e;
          border-radius: 6px;
          &:focus-within {
            outline: none;
          }
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        label {
          // margin-right: 0.5em;
        }
        .line {
          width: 1em;
          height: 1px;
          background-color: gray;
          margin: 0 0.5em;
        }
      }
    }
    & > .filter-header {
      display: flex;
      align-items: center;
      p {
        margin: 0;
      }
      svg {
        width: 1em;
        margin-left: 1em;
        cursor: pointer;
      }
    }
    & > .row-1,
    & > .row-2 {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1em;
      & > div {
        width: 100%;
      }
    }
  }
  h4 {
    font-size: 1.25rem;
    font-weight: 500;
  }
}
