$off-gray-bg: #e6f0f4;

html {
  .shop-analyzer {
    display: flex;
    position: relative;

    main {
      & > div {
        .content {
          .primary-content {
            h4 {
              width: 29%;
            }
          }
        }
      }
      .title {
        border-radius: 6px 6px 0 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // border-inline: 0.5px solid rgb(222, 222, 222);
        // border-top: 0.5px solid rgb(222, 222, 222);
        // background-color: white;
        // background-color: $off-gray-bg;
        background-color: transparent;
        margin-top: 16px;
        padding: 2em 1.5vw 1em 1.5vw;
        span {
          margin-right: 1em;
          border: 1px solid rgb(158, 158, 158);
          height: 4em;
          aspect-ratio: 1;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 100%;
            border-radius: 50%;
          }
        }
        & > div {
          h2 {
            margin: 0;
            padding: 0;
          }
          div {
            margin: 0;
            padding: 0;
            font-size: 0.9rem;
            display: flex;
            align-items: center;
            img {
              height: 1em;
              margin-left: 0.25em;
            }
          }
        }
      }
    }
  }
}
