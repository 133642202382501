/* FeeCalculator.css */

/* Styles for the FeeCalculator component */

/* Main container */
.calculator-container {
  padding: 40px;
  padding-left: 25vw; /* Adjust this value based on your Navbar width */
  padding-right: 20vw;
  font-family: "Poppins", sans-serif;
  /* border: 2px solid purple; */
}

/* Headings */
.calculator-container h2 {
  color: #5c00cd;
}

/* Form elements */
.calculator-container form {
  margin-bottom: 20px;
  /* border: 2px solid purple; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 15px;
}

.calculator-container label {
  display: block;
  margin-bottom: 5px;
  color: black; /* Set label text color to black */
}

#checkboxContainer {
  display: flex;
  flex-direction: column;
}
#checkboxContainer label {
  display: inline;
}

.calculator-container input[type="number"] {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #b274ff;
  background-color: white;
  color: black; /* Set input text color to black */
  /* margin-bottom: 10px; */
  outline: none;
  transition: 0.1s;
  box-shadow: 0px 10px 20px -12px rgb(157, 157, 157);
}

.calculator-container input[type="number"]:hover {
  /* background-color: red; */
  transition: 0.1s;
  border: 1px solid #5c00cd;
}

.calculator-container input[type="checkbox"] {
  margin-left: 10px;
  transform: scale(1.3);
  margin-bottom: 10px;
}

.calculator-container select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #5c00cd;
  background-color: white;
  color: black; /* Set select text color to black */
  margin-top: 5px;
}

/* Calculated fees section */
.calculator-container h3 {
  color: #5c00cd;
  margin-bottom: 10px;
}

.calculator-container button {
  background-color: #5c00cd;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}

/* Paragraph styles for calculated fees */
.calculator-container p {
  color: black; /* Set paragraph text color to black */
  margin-bottom: 5px;
}

.fees-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  background-color: white;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.fees-grid p {
  color: black;
  margin: 0 0 0 10px;
}
