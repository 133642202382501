/* CSS for MemberSetting component */

/* Styles for the 'addmembers' section */
.addmembers {
    margin-bottom: 20px;
  }
  
  .addmembers button {
    padding: 10px;
    background-color: #5c00cd;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .addmembers button:hover {
    background-color: #410093;
  }
  
  /* Styles for the 'Access' section */
  .Access {
    margin-bottom: 30px;
  }
  
  .Access h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .Access ul {
    list-style: none;
    padding: 0;
  }
  
  .Access li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .Access li button {
    padding: 5px 10px;
    background-color: #5c00cd;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .Access li button:hover {
    background-color: #410093;
  }
  
  /* Styles for the popup background and content */
  .popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .popup-content button {
    padding: 8px 16px;
    background-color: #5c00cd;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .popup-content button:hover {
    background-color: #410093;
  }
  