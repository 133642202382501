.dropdown-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    font-family: Arial, sans-serif;
  }
  
  .dropdown-form label {
    display: block;
    margin-bottom: 8px;
  }
  
  .dropdown-form input[type='text'],
  .dropdown-form input[type='password'] {
    width: 500px; /* Adjust width and other properties as needed */
    padding: 8px;
    margin-bottom: 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
  }
  
  .dropdown-form .accessgiver {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .dropdown-form .accessgiver > div {
    display: flex;
    flex-direction: column;
    
  }
  
  .dropdown-form .accessgiver label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
  }
  
  .dropdown-form button {
    background-color: #5c00cd;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .dropdown-form button:hover {
    background-color: #0056b3;
  }
  
  .dropdown-form p {
    font-size: 12px;
    margin-top: 10px;
    line-height: 1.4;
  }
  