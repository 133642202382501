/* Container Styles */
.stbody {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  /* Payment Form Styles */
  .stripe-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 320px;
  }
  
  .stripe-form div {
    margin-bottom: 15px;
  }
  
  .stripe-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .stripe-form input[type="email"] {
    width: calc(100% - 20px);
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .bill {
    margin-bottom: 20px;
  }
  
  .bill h3 {
    font-size: 20px;
    font-weight: bold;
  }
  
  .stripe-button {
    height: 40px;
    width: auto;
    padding: 0 20px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background-color: #ffc439;
    color:black;
    transition: background-color 0.3s ease;
  }
  
  .stripe-button:hover {
    background-color: #ffcc00; /* Lighter shade of yellow on hover */
  }
  