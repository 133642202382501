.dashboard {
  main {
    .top {
      h2 {
        margin: 0.25em 0 0.25em 1.25em;
        color: white;
        font-weight: 500;
      }
    }
    .content {
      width: 93%;
      display: flex;
      justify-content: space-between;
      .column-1,
      .column-2 {
        width: 49%;
        & > div {
          width: 100%;
          background-color: white;
          border: 1px solid rgb(201, 201, 201);
          border-radius: 6px;
          padding: 3em 2em;
          margin: 1em 0;
          h4 {
            margin: 0;
            font-size: 1.25rem;
            font-weight: 500;
          }
        }
        & > div:nth-child(1) {
          p {
            width: 75%;
            font-size: 0.85rem;
            text-align: left;
            margin: 1em 0 2em 0;
          }
          button {
            font-size: 1rem;
            padding: 0.75em 2em;
            border-radius: 8px;
            border: none;
            background-color: #5c00cd;
            a {
              text-decoration: none;
              color: white;
            }
            &:hover {
              background-color: #6a00ec;
            }
          }
        }
      }
      .column-2 {
        & > div {
          width: 100%;
          padding-left: 3em;
          h4 {
            margin: 0;
            font-size: 1.25rem;
            font-weight: 500;
          }
          ul {
            margin-bottom: 0;
            li {
              list-style: disc;
              list-style-position: inside;
              font-size: 0.85rem;
              text-align: left;
              margin: 1em 0 0em 0;

              p:nth-child(1) {
                margin: 0;
                // font-weight: 500;
              }
              p:nth-child(2) {
                width: fit-content;
                margin: 0;
                opacity: 0.75;
              }
            }
          }
        }
      }
    }
    div.credits-wrapper {
      width: 47.5%;
      h4 {
        white-space: nowrap;
      }
      & > div {
        div:nth-child(1) {
          display: flex;
          align-items: center;
          h4 {
            margin: 0;
          }
          p {
            margin: 0;
            margin-left: 0.5em;
          }
        }
        div:nth-child(2) {
          margin: 0.5em 0 0 0;
          display: flex;
          align-items: center;
          font-size: 2rem;
          .progress-bar {
            width: 50%;
            height: 16px;
            background-color: #c8c8c8;
            margin-left: 0.5em;
            border-radius: 16px;
            overflow: hidden;
            div {
              height: 100%;
              background-color: rgb(3, 64, 148);
              border-radius: 16px;
            }
          }
        }
      }
      svg {
        height: 1em;
        cursor: pointer;
      }
    }
  }
}
