/* Plans.css */

/* Styles for the main container */
.plans-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* Styles for the plans section */
  .plans-section {
    margin-bottom: 30px;
  }
  
  /* Styles for the plan cards */
  .plans-cards {
    display: flex;
    justify-content: space-between;
  }
  
  .plan-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    width: calc(33.33% - 20px); /* Adjust as needed */
    box-sizing: border-box;
  }
  
  .plan-card h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .plan-card p {
    margin: 5px 0;
    font-size: 16px;
  }
  
  .plan-card button {
    padding: 8px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .plan-card button:hover {
    background-color: #0056b3;
  }
  
  /* Styles for the payment section */
  .payment-section {
    text-align: center;
  }
  
  .payment-options button {
    margin: 10px;
    padding: 8px 15px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .payment-options button:hover {
    background-color: #218838;
  }


  /* Plans.css */

/* Styles for the form */
.plans-container form {
    margin-bottom: 20px;
  }
  
  .plans-container form div {
    margin-bottom: 15px;
  }
  
  .plans-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .plans-container input[type='email'],
  .plans-container input[type='password'] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .plans-container input[type='email']:focus,
  .plans-container input[type='password']:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .plans-container input[type='email']::placeholder,
  .plans-container input[type='password']::placeholder {
    color: #aaa;
  }
  
  /* Submit button */
  .plans-container  form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .plans-container form button:hover {
    background-color: #0056b3;
  }

  .plans-container {
    width: 700px;
    margin: 0 auto; /* This centers the container horizontally */
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    
}

.pagecon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  background-color: #f0f0f0; /* Set the desired background color */
}
  
  