@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  background-color: #f0f0f0;
}
html ul {
  list-style: none;
  padding: 0;
}
html .toggle-button-container {
  display: flex;
}
html .toggle-button-container button {
  padding: 0.75em 1em;
  border: none;
  border-radius: 6px;
  background-color: white;
  border: 1px solid #acacac;
  cursor: pointer;
}
html .toggle-button-container button + button {
  margin-left: 1em;
}
html .toggle-button-container button.selected {
  background-color: #1872f6;
  border: none;
  color: white;
}
html .body {
  padding: 8px;
}
html .body button {
  padding: 0.5em 1em;
  border: none;
  border-radius: 6px;
  border: 1px solid #acacac;
  cursor: pointer;
}
html .body button + button {
  margin-left: 1em;
}
html .body button.selected {
  background-color: #1872f6;
  border: none;
  color: white;
}
html .body main {
  position: relative;
  top: 0px;
  left: calc(19.5vw + 16px);
  width: calc(79vw - 32px);
  border-radius: 6px;
}
html .body main > div {
  width: 100%;
}
html .body main > div .content {
  margin-bottom: 4em;
  min-height: 400px;
  border-radius: 0 0 6px 6px;
  width: 100%;
  background-color: transparent;
}
html .body main > div .content .primary-content {
  display: flex;
}
html .body main > div .content .primary-content h4 {
  font-weight: 400;
  width: 25%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1em 2em 1em 2em;
  border-radius: 6px;
  border: 0.5px solid rgb(222, 222, 222);
  background-color: white;
  box-shadow: 0px 2px 12px 0px rgba(153, 173, 207, 0.4);
}
html .body main > div .content .primary-content h4 p {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
  padding: 0.25em 0 0 0;
  height: 1.5em;
}
html .body main > div .content .primary-content h4 + h4 {
  margin-left: 2em;
}
html .body main > div .content .secondary-content .tables {
  display: flex;
}
html .body main > div .content .secondary-content .tables .table + .table {
  margin-left: 2rem;
}
html .body main > div .content .secondary-content .tables .table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  padding: 1em 1.5em;
  border-radius: 6px;
  border: 0.5px solid rgb(222, 222, 222);
  background-color: white;
  box-shadow: 0px 2px 12px 0px rgba(153, 173, 207, 0.4);
}
html .body main > div .content .secondary-content .tables .table h5 {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
html .body main > div .content .secondary-content .tables .table ul {
  margin: 1em 0 0 0;
}
html .body main > div .content .secondary-content .tables .table ul li {
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  padding: 0.75em 0;
  border-top: 1px solid rgb(194, 202, 212);
}
html .body main > div .content .secondary-content .tables .table ul li p {
  margin: 0;
}
html .body main > div .content .secondary-content h4 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 2em;
}
html nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 19.5vw;
  height: calc(100vh - 16px);
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 1.5vw 0 1.5vw;
  border-radius: 6px;
  background-color: #002239;
  z-index: 10;
}
html nav svg {
  width: 2em;
  height: 2em;
  margin-right: 0.75em;
}
html nav a:has(img) {
  width: 10vw;
  align-self: center;
  margin: 2em 0 2em 0;
}
html nav .options {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 0vw);
}
html nav .options ul {
  flex-direction: column;
  margin-block: 0.5em;
  font-size: 0.8rem;
  width: 100%;
}
html nav .options ul li {
  width: 100%;
  cursor: pointer;
  padding: 0.75em 1.5em;
  margin: 0 0 0.5em 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
html nav .options ul li a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
html nav .options ul li.selected {
  background-color: #00435c;
}
html nav .options ul li:hover {
  background-color: #013141;
}
html nav .options .bottom {
  padding: 0 1.5em 2em;
  font-size: 0.8rem;
}
html nav .options .bottom div.credits-wrapper div:has(.progress-bar) {
  display: flex;
  justify-content: left;
  align-items: center;
}
html
  nav
  .options
  .bottom
  div.credits-wrapper
  div:has(.progress-bar)
  .progress-bar {
  width: 100%;
  height: 8px;
  background-color: #c8c8c8;
  margin-left: 0.5em;
  border-radius: 4px;
  overflow: hidden;
}
html
  nav
  .options
  .bottom
  div.credits-wrapper
  div:has(.progress-bar)
  .progress-bar
  div {
  height: 100%;
  background-color: #034094;
  border-radius: 4px;
}
html nav .options .bottom p:not(.sign-out-button):not(.email) {
  color: white;
  margin: 0;
  font-weight: 300;
  font-size: 0.9rem;
}
html nav .options .bottom div.email {
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  margin-bottom: 0;
}
html nav .options .bottom div.email div {
  display: grid;
  place-items: center;
  font-size: 1rem;
  width: 2.5em;
  height: 2.5em;
  margin-right: 0.5em;
  border-radius: 50%;
  background-color: #005675;
}
html nav .options .bottom .more {
  position: relative;
  color: white;
}
html nav .options .bottom .more > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  transform: translateY(-110%);
  padding: 1.75em;
  border-radius: 6px;
  background-color: #005675;
}
html nav .options .bottom .more > div:nth-child(1) a {
  color: white;
  text-decoration: none;
}
html nav .options .bottom .more > div:nth-child(1) .sign-out-button {
  color: #ffa9a9;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 0;
}
html nav .options .bottom .more > div:nth-child(2) {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 2em;
}
html nav .options .bottom .more > div:nth-child(2) svg {
  width: 1.5em;
  height: 1.5em;
}
html .top {
  background-color: #002239;
  padding: 1em 0;
  border-radius: 6px;
}
html .page {
  position: relative;
  background-color: transparent;
}
html .page .container {
  width: 100vw;
  min-height: 100vh;
  box-shadow: 0px 0px 107px 15px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
}
html .page .container button {
  font-family: "Poppins", sans-serif;
}
html .page .container .search-container {
  position: relative;
  width: 50%;
  margin-inline: auto;
}
html .page .container .search-container form {
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  border: 1px solid #e4e0e0;
  border-radius: 8px;
  background-color: white;
}
html .page .container .search-container form input {
  border: none;
  width: 100%;
  font-size: 1.05rem;
}
html .page .container .search-container form input:focus {
  outline: none;
}
html .page .container .search-container form svg {
  margin-inline: 0.5em;
  cursor: pointer;
}
html .page .container .search-container form button {
  padding: 0.5em 1em;
  border: none;
  border-radius: 6px;
  color: white;
  background-color: #5c00cd;
}
html .page .container .search-container #search-matches {
  position: absolute;
  display: none;
  width: max(25vw, 250px);
  padding: 0.75em 1em;
  font-size: 0.75rem;
  border: 1px solid #e4e0e0;
  border-radius: 12px;
  background-color: white;
  margin-top: 0.5em;
}
html .page .container .search-container #search-matches div {
  display: flex;
  align-items: center;
}
html .page .container .search-container #search-matches div p {
  margin: 0;
  width: 100%;
}
html .page .container .search-container #search-matches div > button {
  font-size: 0.75rem;
  margin-inline: 0.5em;
}
html .page .container .search-container #search-matches table {
  font-size: 0.75rem;
  background-color: white;
}
html .page .container::-webkit-scrollbar {
  width: 8px;
  height: 0;
  background: white;
}
html .page #dataContainer {
  display: flex;
  margin-top: 1em;
  overflow-x: auto;
  padding-bottom: 1em;
}
html .page #dataContainer a {
  text-decoration: none;
  color: #170231;
}
html .page #dataContainer a > div {
  border-radius: 6px;
  padding: 0.4em 0.5em 1em 0.5em;
  background-color: white;
  box-shadow: 0px 2px 8px 0px rgba(153, 173, 207, 0.4);
}
html .page #dataContainer a > div div:nth-child(1) {
  width: max(12vw, 120px);
  height: max(12vw, 120px);
  position: relative;
  overflow: hidden;
  background-color: #f6f6f6;
}
html .page #dataContainer a > div div:nth-child(1) img {
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}
html .page #dataContainer a > div p {
  font-size: 0.75rem;
  text-align: left;
  width: max(12vw, 120px);
}
html .page #dataContainer a > div div:has(span) {
  display: flex;
}
html .page #dataContainer a > div div:has(span) span {
  font-size: 0.75rem;
  text-align: left;
  display: flex;
  align-items: center;
  margin-right: 0.75em;
}
html .page #dataContainer a > div div:has(span) span svg {
  margin-left: 0.25em;
}
html .page #dataContainer a + a {
  margin-left: 1em;
}
html .page #dataContainer::-webkit-scrollbar {
  height: 8px;
  background: white;
}
html .page footer {
  left: calc(19.5vw + 24px);
  width: calc(79vw - 32px);
  margin-top: 2em;
  position: absolute;
  bottom: 6px;
  border-radius: 6px;
}
html .page footer p {
  padding: 1em 0 1em 1.5vw;
  color: rgb(87, 87, 87);
  font-size: 0.9rem;
  margin: 0;
  text-align: center;
}

button {
  cursor: pointer;
}

*::-webkit-scrollbar-track {
  border-radius: 100vw;
  background-color: #f6f6f6;
}

*::-webkit-scrollbar-thumb {
  border-radius: 100vw;
  background: #b8d5dd;
}

*::-webkit-scrollbar-thumb:hover {
  background: lightblue;
}

/* @supports (scrollbar-color: red blue) {
  * {
    scrollbar-color: red blue;
  }
} */
@media (max-width: 768px) {
  ::-webkit-scrollbar-track {
    margin-top: 0;
  }
} /*# sourceMappingURL=global.css.map */
