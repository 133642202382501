.loader-wrapper {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  display: flex;
  padding: 0.5rem;
  gap: 0.5em;
  border-radius: 9999px;
  width: fit-content;
  div {
    background-color: gray;
    // padding: 0.5em;
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    animation: bounce 1s infinite;
  }
  div:nth-child(1) {
    animation-delay: 0.1s;
  }
  div:nth-child(2) {
    animation-delay: 0.2s;
  }
  div:nth-child(3) {
    animation-delay: 0.3s;
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(-40%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
