/* PaymentsPaypal.css */

/* Centering the form */
.paypal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5; /* Adjust background color as needed */
  }
  
  /* Form styles */
  .paypal.form {
    width: 320px; /* Adjust form width as desired */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .paypal.form div {
    margin-bottom: 15px;
  }
  
  .paypal.form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .paypal.form input[type="email"],
  .paypal.form input[type="password"] {
    width: calc(100% - 20px);
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .bill {
    margin-bottom: 20px;
  }
  
  .bill h3 {
    font-size: 20px;
    font-weight: bold;
  }
  
  .paypal-buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  /* Style PayPal button */
  .paypal-button {
    height: 40px; /* Adjust button height */
    width: auto;
    padding: 0 20px; /* Adjust button padding */
    font-size: 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background-color: #0070ba; /* Adjust button color */
    color: #fff;
    transition: background-color 0.3s ease;
  }
  
  .paypal-button:hover {
    background-color: #005a8c; /* Adjust hover color */
  }
  