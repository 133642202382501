.product-finder .secondary-search-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  margin-top: 1em;
  padding: 1.5em;
  border-radius: 6px;
  background-color: white;
}
.product-finder .secondary-search-container label {
  width: 7em;
  text-align: right;
  padding-right: 20px;
  /* border: 2px solid black; */
}
.product-finder .secondary-search-container > div > div {
  display: flex;
  align-items: center;
  margin-right: 1em;
  /* Firefox */
}
.product-finder .secondary-search-container > div > div .valInput {
  width: 9em;
  /* margin-top: 10px; */
  padding: 0.75em 1em;
  /* border: none; */
  border: 1px solid #9e9e9e;
  /* border: 2px solid red; */
  margin-top: 5px;
  border-radius: 6px;
}
.product-finder .secondary-search-container > div > div input:focus-within {
  outline: none;
}
.product-finder
  .secondary-search-container
  > div
  > div
  input::-webkit-outer-spin-button,
.product-finder
  .secondary-search-container
  > div
  > div
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.product-finder .secondary-search-container > div > div input[type="number"] {
  -moz-appearance: textfield;
}
.product-finder .secondary-search-container > div > div .line {
  width: 1em;
  height: 1px;
  background-color: gray;
  margin: 0 0.5em;
}
.product-finder .secondary-search-container > .filter-header {
  display: flex;
  align-items: center;
}
.product-finder .secondary-search-container > .filter-header p {
  margin: 0;
}
.product-finder .secondary-search-container > .filter-header svg {
  width: 1em;
  margin-left: 1em;
  cursor: pointer;
}
.product-finder .secondary-search-container > .row-1,
.product-finder .secondary-search-container > .row-2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}
.product-finder .secondary-search-container > .row-1 > div,
.product-finder .secondary-search-container > .row-2 > div {
  width: 100%;
}
.product-finder h4 {
  font-size: 1.25rem;
  font-weight: 500;
} /*# sourceMappingURL=productFinder.css.map */

/* Style for the listings section */
.listings_5478_12 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Style for the Listings title */
.listings_5478_12 h4 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

/* Style for the Export to CSV button */
.listings_5478_12 button {
  background-color: #002239;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Hover effect for the Export to CSV button */
.listings_5478_12 button:hover {
  background-color: #092e47;
}
.sticky-header-table {
  width: 100%;
  border-spacing: 20px;
  table-layout: fixed;
}

.sticky-header-table th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.customise-options {
  margin-top: 10px;
}
.dropdown-content {
  /* border: 1px solid purple; */
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}
.dropdown-content label {
  /* border: 1px solid red; */
  display: flex;
  gap: 5px;
  padding-left: 40px;
}
.custom-checkbox {
  width: 10px;
  /* border: #002239; */
  transform: scale(1.2);
}

.filters {
  /* background-color: rgb(219, 216, 216); */
  background-color: rgb(228, 226, 226);
  /* color: white; */
  border-radius: 10px;
  padding: 10px 0;
  min-height: 150px;
}

.dropdown-container {
  background-color: rgb(228, 226, 226);
  /* color: white; */
  border: none;
  box-shadow: 0px 3px 10px -5px gray;
}
.dropbtn {
  transition: 0.2s;
}
.dropbtn:hover {
  transition: 0.2s;
  transform: scale(1.01);
  border: 1px solid gray;
  background-color: rgb(228, 226, 226);
  font-weight: 500;
}
