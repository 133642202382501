.loader-wrapper {
  display: flex;
  padding: 0.5rem;
  gap: 0.5em;
  border-radius: 9999px;
  width: -moz-fit-content;
  width: fit-content;
}
.loader-wrapper div {
  background-color: gray;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  animation: bounce 1s infinite;
}
.loader-wrapper div:nth-child(1) {
  animation-delay: 0.1s;
}
.loader-wrapper div:nth-child(2) {
  animation-delay: 0.2s;
}
.loader-wrapper div:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-40%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}/*# sourceMappingURL=loader.css.map */