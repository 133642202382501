/* GeneralSetting.css */

/* Styles for the container div */
.Gesetting {
    display: flex;
    flex-direction: column;
    
    margin-top: 20px;
  }
  
  /* Styles for the "Change Password" button */
  .changepass button {
    background-color: #5c00cd;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    text-decoration: none;
  }
  .changepass button a {
    color: white;
    text-decoration: none;
  }
  
  /* Styles for the "Sign Out" button */
  .signout button {
    background-color: #ff3333;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
  }
  /* CSS for the currentuser section */
.currentuser {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.currentuser p {
  margin: 5px 0;
}

.currentuser p:first-child {
  font-weight: bold;
}

  