@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.keyword-finder {
  #header-1,
  #header-2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2em;
    width: fit-content;

    .title {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      h2 {
        display: flex;
        align-items: center;
        padding: 0 1.5vw;
        font-weight: 500;
        font-size: 1.5rem;
        margin: 0;
        p {
          font-size: 1.25rem;
          margin: 0 0.5em 0 0;
        }
      }
      span {
        margin-bottom: 0.25em;
      }
    }

    svg {
      cursor: pointer;
    }
    svg:nth-child(2) {
      margin-left: 1em;
    }
  }
  section {
    // padding: 0 1.5vw;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1.25fr;
    // gap: 1em;

    &.listings {
      display: block;
      h6 {
        font-size: 1.1rem;
        font-weight: 500;
        margin: 2em 0 1em 0;
      }
    }

    // .toggle-button-container {
    //   display: flex;
    //   button {
    //     padding: 0.75em 1em;
    //     border: none;
    //     border-radius: 6px;
    //     background-color: white;
    //     border: 1px solid #acacac;
    //     cursor: pointer;
    //   }
    //   button + button {
    //     margin-left: 1em;
    //   }
    //   button.selected {
    //     background-color: #1872f6;
    //     border: none;
    //     color: white;
    //   }
    // }

    // table {
    //   border-spacing: 10px;
    //   font-size: 0.9rem;
    //   width: 100%;
    //   background-color: #dff4ff;
    //   margin-block: 1em;
    //   tr {
    //     th:nth-child(1) {
    //       text-align: left;
    //     }
    //     th:nth-child(2) {
    //       text-align: right;
    //     }
    //     td[data-th="Search Appearances"] {
    //       text-align: right;
    //     }
    //   }
    // }
  }
}
.table-wrapper {
  display: block;
  width: 100%;
  padding: 0 1.5vw;
  margin: 2em 0 16vh 0;
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    border-radius: 12px;
    // border: 1px solid #cfcfcf;
    tr {
      border-top: 1px solid #cfcfcf;
      font-size: 0.9rem;
      th,
      td {
        text-align: left;
        padding: 1.5em 0 1.5em 2em;
      }
      th {
        text-transform: capitalize;
        opacity: 0.6;
        font-weight: 600;
      }
      td.product-title-wrapper {
        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-decoration: none;
          color: black;
          div:has(img) {
            width: 10vh;
            height: 10vh;
            position: relative;
            overflow: hidden;
            border-radius: 6px;
            margin-right: 1em;
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              max-width: 100%;
              max-height: 100%;
              border-radius: 6px;
              // width: 100%;
            }
          }
        }
      }
    }
    thead tr {
      border: none;
      background-color: rgb(222, 222, 222);
      & th:nth-child(1) {
        border-radius: 12px 0 0 0;
      }
      & th:last-child {
        border-radius: 0 12px 0 0;
      }
      th {
        padding-block: 1.25em;
        cursor: pointer;
        & > div {
          display: flex;
          justify-content: left;
          align-items: center;

          .sort-icon {
            position: relative;
            width: 0.9rem;
            height: 0.9rem;
            margin-left: 1em;
            .triangle-up,
            .triangle-down {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 0;
              height: 0;
              border-left: 0.4em solid transparent;
              border-right: 0.4em solid transparent;
            }

            .triangle-up {
              border-bottom: 0.4em solid #000; /* Change color as needed */
            }

            .triangle-down {
              top: 0.6em;
              border-top: 0.4em solid #000; /* Change color 
              as needed */
            }
          }
        }
      }
    }
  }
}
